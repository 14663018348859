import React from 'react'
import tw, { styled } from 'twin.macro'
import LinkButton from '@elements/LinkButton'
import Stripes from '@elements/Stripes'
import parse from 'html-react-parser'
import EmfImage from '../elements/EmfImage'

const Section = tw.section``
const Headline = tw.h2`relative md:absolute z-20`
const Text = styled.p`
	${tw`z-10 w-3/4 mt-8 italic text-black md:absolute text-10px md:mt-0 sm:w-2/5 md:text-xs xl:text-sm tracking-1 md:tracking-2 md:text-white`}
`
const ImageWrap = tw.div`relative `
const SmallImageWrap = tw.div`relative`
const BlockWrap = tw.div`relative w-full mx-auto mb-10 lg:max-w-screen-xl px-5 md:px-10 xl:px-0`

const Block = styled.div`
	${tw`relative w-full`}

	&.br-1 {
		${tw`-mt-10 sm:mt-0`}
		${ImageWrap} {
			${tw`md:w-2/3`}
		}
		${Headline} {
			${tw`relative z-10 px-5 translate-y-0 bottom-full md:left-1/3 md:w-2/3 md:pr-offset-master md:max-w-screen-xl mb-spacingXs-xs`}
		}
		${Text} {
			${tw`w-7/12 mt-spacingM-master md:mt-0 sm:w-2/5 left-5 md:top-8 md:left-10 xl:top-10 xl:left-14`}
		}
	}
	&.br-2 {
		${ImageWrap} {
			${tw`ml-auto md:w-2/3`}
		}
		${Headline} {
			${tw`relative left-0 z-10 md:left-5 md:top-20 md:w-7/12 xl:w-8/12 md:pr-10 mb-spacingXs-xs mt-spacingXs-master md:mt-0`}
		}
		${Text} {
			${tw`bottom-5 left-5 md:bottom-10 md:left-14`}
		}
	}
	&.br-3 {
		${BlockWrap} {
			${tw`relative md:flex mb-spacingXl-master md:mb-0`}
		}
		${ImageWrap} {
			${tw`w-11/12 xs:w-3/4 md:w-[53%] h-[350px] md:h-[450px] lg:h-[550px] xl:h-[750px]`}
		}
		${SmallImageWrap} {
			${tw`relative w-1/2 ml-auto -translate-y-6 md:right-0 md:bottom-0 md:top-0 md:translate-y-16 md:w-1/3 md:-mb-32 md:relative md:mt-auto md:ml-5`}
		}
		${Headline} {
			${tw`relative mb-spacingXs-xs mt-spacingXs-master md:mt-0 md:mb-0 md:absolute right-0 z-10 md:top-10  md:w-[47%] md:px-5 lg:px-10`}
		}
		${Text} {
			${tw`w-1/2 bottom-5 left-5 md:bottom-10 md:left-14`}
		}
	}
	&.br-4 {
		${BlockWrap} {
			${tw`mt-24 xs:mt-0 md:mt-48 md:flex md:mb-0`}
		}
		${ImageWrap} {
			${tw`ml-auto md:w-[53%] md:h-[450px] lg:h-auto min-h-[450px]`}
		}
		${Headline} {
			${tw`relative left-0 z-10 mb-spacingXs-xs mt-spacingXs-master md:mt-0 md:top-20 md:w-[47%] md:pr-10`}
		}
		${Text} {
			${tw`bottom-5 left-5 md:bottom-10 md:left-14`}
		}
	}
`

const WpAcfStoryblocksBlock = ({ data }) => {
	const { anchor } = data.attributes
	const module = data.modulesStoryBlocks

	return (
		module.blocks?.length > 0 && (
			<Section {...(anchor !== '' ? { id: anchor } : {})} className='emf-pb-spacing'>
				<div className='flex flex-col pb-24 md:pb-0'>
					{module.blocks.map((block, index) => {
						const layout = block.layout === 'default' || block.layout === null ? index + 1 : parseInt(block.layout)
						return (
							block.image?.localFile && (
								<Block className={`br-${layout}`} key={`sb-${index}-${layout}`}>
									{layout === 1 && <Stripes atFront className='rot-x-180 md:!rotate-0 md:z-10 bottom-spacingM-master xs:bottom-spacingM-xs md:bottom-10' />}
									{layout === 4 && <Stripes layout='rotated' className='top-full' />}
									{block.headline && index === 0 && (
										<Headline className='headline-h2'>
											{parse(block.headline)}
											{block.button && <LinkButton className='z-10 hidden mt-8 font-futuraBook md:flex' target={block.button.target} href={block.button.url} label={block.button.title} />}
										</Headline>
									)}
									<BlockWrap>
										{block.headline && index !== 0 && (
											<Headline className='headline-h2'>
												{parse(block.headline)}
												{block.button && <LinkButton className='relative z-10 hidden mt-8 font-futuraBook md:flex' target={block.button.target} href={block.button.url} label={block.button.title} />}
											</Headline>
										)}
										<ImageWrap>
											<EmfImage src={block.image?.localFile} className='w-full h-full rounded-emf' speed={0.04} moveDown={false} altText={block.image?.altText} />
											{block.text && <Text>{block.text}</Text>}
											{block.button && <LinkButton className='relative z-10 flex mt-8 font-futuraBook md:hidden' target={block.button.target} href={block.button.url} label={block.button.title} />}
										</ImageWrap>
										{block.smallImage && layout === 3 && (
											<SmallImageWrap>
												<EmfImage src={block.smallImage?.localFile} className='rounded-emf max-h-[450px]' speed={0.04} moveDown={true} altText={block.smallImage?.altText} />
											</SmallImageWrap>
										)}
									</BlockWrap>
								</Block>
							)
						)
					})}
				</div>
			</Section>
		)
	)
}

export default WpAcfStoryblocksBlock
